import React, { useEffect, useRef, useState } from "react";
import "./MainScreen.scss";
// import daylightLog from '../../assets/images/customer-portal-logo.png';
// import homeImage from '../../assets/images/customer-portal-img.png';
import homeImage2 from "../../assets/images/LoginImage2.png";
// import homeImageMobile from '../../assets/images/custom-portal-mobile.png';
// import telephoneLogo from '../../assets/images/CallLogo.png';
import emailLogo from "../../assets/images/email-logo.svg";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
import {
  decryptionAES,
  // formatPhoneNumber,
  // handleDecrypt,
  isValidURL,
  redirectToExternalUrl,
} from "../../utils/util";
import { useUserAuth } from "../../providers/UserAuthContext";
import { REQUEST_TOKEN_MUTATION } from "../../GraphQL/Mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { APIService } from "../../shared/api-client/api.services";
import toast from "react-hot-toast";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase.config";
import Button from "../Button/Button";
// import InputField from '../InputFields/InputField';
import OtpInput from "otp-input-react";
import { CgSpinner } from "react-icons/cg";
import RedirectToAppStoreAndPlayStore from "../RedirectToAppStoreAndPlayStore";
// import { twMerge } from 'tailwind-merge';
import { LEAD_EXISTS } from "../../GraphQL/Queries";
import DayLightLogo from "../../resources/images/DayLightLogo";
import ecoPlusLogo from "../../assets/images/ecoplus_grey.png";
import shoppingBagLogo from "../../assets/images/shopping_bag.svg";

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_LINK;

const MainScreenV1 = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let referralCode = params.get("referralCode");
  let linkNumber = params.get("linkNumber");
  let source = params.get("source");
  const urlFrom = params.get("urlFrom");

  // TODO: Remove this trim after production stable release and also change it to const from let
  referralCode = referralCode && referralCode.replace(/'$/, "");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);

  const [isInvalidOtp, setIsInvalidOtp] = useState(false);
  const { updateUserDetail } = useUserAuth();
  const [user, setUser] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  // const [countdown, setCountdown] = useState(30);
  // const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [sendOTPCounter, setSendOTPCounter] = useState(0);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);
  const [countdown, setCountdown] = useState(20);
  const [isURLValid, setIsURLValid] = useState();

  const [isFromDashboard, setIsFromDashboard] = useState(false);
  const timerIntervalRef = useRef(null);

  const navigate = useNavigate();
  const recaptchaContainerRef = useRef(null);
  const recaptchaVerifier = useRef();

  const { isUserRegistered, setIsUserRegisterd } = useUserAuth();

  const [requestAuthToken] = useMutation(REQUEST_TOKEN_MUTATION);

  const [email, setEmail] = useState("");
  const [promoCode, setPromoCode] = useState("");

  // const [isValidEmail, setIsValidEmail] = useState(false);

  const validateEmail = (email) => {
    if (!email) {
      // If email is empty, return true (optional field)
      return true;
    }
    // Regular expression to check email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailRegex.test(email);
    console.log("isValid email :", isValid);

    return isValid;
  };

  const isValidEmail = validateEmail(email);

  const [
    checkLeadByPhoneNumberQuery,
    {
      loading: checkLeadByPhoneNumberQueryLoading,
      data: checkLeadByPhoneNumberQueryData,
    },
  ] = useLazyQuery(LEAD_EXISTS);

  // const isURLValid = urlFrom && isValidURL(decryptedUrl(urlFrom));
  // console.log('isURLValid :', isURLValid);

  const createLog = async (logData) => {
    try {
      const created = await APIService.LOGGER.POST(logData);
      if (created) {
        console.log("created :", created);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const cleanedPhoneNumber = ph.replace(/[^\d]/g, "");
  const cleanedPhoneNumber = "1" + ph.replace(/[^\d]/g, ""); //this is only for US

  // Check if the phone number starts with +1 and has a length of 11 digits
  const isPhoneNumberValid =
    cleanedPhoneNumber.startsWith("1") && cleanedPhoneNumber.length === 11;
  // ||
  // // Check if the phone number starts with +91 or +66 and has a length of 12 digits
  // (cleanedPhoneNumber.startsWith('91') && cleanedPhoneNumber.length === 12) ||
  // (cleanedPhoneNumber.startsWith('81') && cleanedPhoneNumber.length === 12) ||
  // (cleanedPhoneNumber.startsWith('82') && cleanedPhoneNumber.length === 12) ||
  // (cleanedPhoneNumber.startsWith('62') && cleanedPhoneNumber.length === 13) ||
  // (cleanedPhoneNumber.startsWith('66') && cleanedPhoneNumber.length === 11) ||
  // (cleanedPhoneNumber.startsWith('44') && cleanedPhoneNumber.length === 11);

  console.log(isPhoneNumberValid);

  // const isPhoneNumberValid =
  //   ph.replace(/[^\d]/g, '').length >= 11 && ph.replace(/[^\d]/g, '').length < 13;
  // console.log(isPhoneNumberValid);

  const destroyRecaptchaVerifier = () => {
    // Create a new empty container for Recaptcha
    const newRecaptchaContainer = document.createElement("div");

    // Check if recaptchaContainerRef.current has a parent node before replacing it
    if (recaptchaContainerRef.current.parentNode) {
      recaptchaContainerRef.current.parentNode.insertBefore(
        newRecaptchaContainer,
        recaptchaContainerRef.current
      );
      recaptchaContainerRef.current.parentNode.removeChild(
        recaptchaContainerRef.current
      );
      recaptchaContainerRef.current = newRecaptchaContainer;
    }
  };

  const checkLeadExist = async () => {
    try {
      const formatPh = "+" + ph;
      const isLeadExist = await checkLeadByPhoneNumberQuery({
        variables: {
          phoneNumber: formatPh,
        },
      });

      //   console.log('isLeadExist :', isLeadExist);

      return isLeadExist.data.leadExists.isExists;
    } catch (error) {}
  };

  const onRecaptchaRender = async () => {
    // await initializeRecaptchaVerifier();
    if (isFromDashboard) {
      const isLead = await checkLeadExist();
      isLead
        ? onSignup()
        : navigate(`/lead-not-found?urlFrom=${encodeURIComponent(urlFrom)}`);
    } else {
      onSignup();
    }
  };
  const onSendCode = () => {
    // Your code to send the OTP
  };
  const onSignup = async () => {
    if (ph.length >= 10) {
      setLoading(true);
      //   destroyRecaptchaVerifier();
      //   let appVerifier = recaptchaVerifier.current;
      let appVerifier = new RecaptchaVerifier(
        recaptchaContainerRef.current,
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA verification success callback
            // console.log('reCAPTCHA verification success');
          },
          "expired-callback": () => {
            // reCAPTCHA verification expired callback
            console.log("reCAPTCHA verification expired");
          },
        },
        auth
      );

      console.log(ph);
      // const formatPh = "+" + ph;
      const formatPh = "+1" + ph; //only for us

      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setVerificationId(confirmationResult.verificationId);
          setLoading(false);
          setShowOTP(true);
          appVerifier.clear();
          // toast.success("OTP sended successfully!");
          console.log("OTP sended successfully!");
          setSendOTPCounter(sendOTPCounter + 1);
          setOtp("");
          setVerificationLoading(true);
          setResendDisabled(true);
          setIsOtpSent(true);
          startTimer();
        })
        .catch(async (error) => {
          console.log("error", error.message);

          const logData = {
            url: "https://firebase.com",
            method: "GET",
            headers: [],
            payload: { phoneNumber: formatPh },
            response_status: 400,
            response_headers: "[]",
            response_body: error && error.message ? error.message : "",
            client: "Web",
            client_os: navigator.platform ? navigator.platform : "browser",
          };
          createLog(logData);

          setLoading(false);
          await destroyRecaptchaVerifier();
          // recaptchaVerifier.current.render();

          switch (error.code) {
            case "auth/quota-exceeded":
              toast.error("Exceeded Quota");
              // navigate(0);
              break;
            case "auth/captcha-check-failed":
              toast.error("Captcha check failed");
              break;
            case "auth/timeout":
              toast.error("TimeOut");
              break;
            case "auth/internal-error":
              toast.error("Internal Error");
              // navigate(0);
              break;
            case "auth/invalid-phone-number":
              toast.error("Invalid Phone Number");
              break;
            default:
          }
        })
        .finally(() => {
          setIsOtpSent(true);
          setVerificationLoading(false);
        });
    }
  };

  const onOTPVerify = (e) => {
    e.preventDefault();
    if (otp.length === 6) {
      setVerificationLoading(true);
      window.confirmationResult
        .confirm(otp)
        .then(async (res) => {
          // console.log(res);
          setUser(res.user);
          setLoading(false);
          updateUserDetail({ userPhoneNumber: "+" + ph, userPhoneOTP: otp });
          // logOut();

          if (res && res.user) {
            setIsOtpSent(false);
            setOtp("");
            console.log("res :", res);
            try {
              // lead_source:
              //                         source && source.toLowerCase() === "outbound"
              //                           ? process.env.REACT_APP_SOURCE_OUTBOND_ID
              //                           : process.env.REACT_APP_REFERRAL_ID,
              const authTokenResponse = await requestAuthToken({
                variables: {
                  input: {
                    firebase: {
                      idToken: res._tokenResponse.idToken,
                      referralCode: process.env.REACT_APP_REFERRAL_CODE ?? ``,
                      linkNumber: linkNumber ?? "",
                      source: source ?? "",
                      email: email ?? ``,
                      leadStatus: process.env.REACT_APP_LEAD_STATUS ?? `45`,
                      promoCode: promoCode ?? ``,
                    },
                  },
                },
              });

              if (authTokenResponse) {
                // console.log("authTokenResponse :", authTokenResponse);
                // setScreenType("firebaseSocialLogin");
                setIsUserRegisterd(true);
                setVerificationLoading(false);
                // setLoginProviderName(null);
                // setProvider(provider);
                // console.log('isURLValid :', isURLValid);
                if (urlFrom && isURLValid && isURLValid.success) {
                  // console.log(
                  //   "window.location.hostname :",
                  //   window.location.hostname
                  // );

                  // const subdomain = window.location.hostname;
                  // const parts = subdomain.split(".");

                  // const parentDomain = parts.slice(-2).join(".");

                  // console.log("parentDomain :", parentDomain);

                  // Cookies.set(
                  //   "daylight_access_token",
                  //   authTokenResponse.data.requestToken.token,
                  //   { domain: parentDomain, secure: true }
                  // );

                  // redirectToExternalUrl(
                  //   urlFrom,
                  //   authTokenResponse.data.requestToken.token
                  // );
                  redirectToExternalUrl(
                    isURLValid.data,
                    authTokenResponse.data.requestToken.nounceToken
                  );
                } else if (isURLValid && !isURLValid.success) {
                  navigate("/404");
                }
                // toast.success("User successfully registered");
              }
            } catch (error) {
              console.log(error);

              toast.error(error.message);
              // firebaseLogout();
              // setScreenType(null);
              setIsUserRegisterd(false);
              // setLoginProviderName(null);
              setVerificationLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.code);
          const logData = {
            url: "https://firebase.com",
            method: "GET",
            headers: [],
            payload: { phoneNumber: "+" + ph, userPhoneOTP: otp },
            response_status: 400,
            response_headers: "[]",
            response_body: err && err.message ? err.message : "",
            client: "Web",
            client_os: navigator.platform ? navigator.platform : "browser",
          };
          createLog(logData);
          setVerificationLoading(false);
          switch (err.code) {
            case "auth/invalid-verification-code":
              // toast.error("Invalid verification code");
              setIsInvalidOtp(true);
              break;
            default:
          }
        });
    }
  };

  const handleResendOTP = async () => {
    setResendDisabled(true);
    setIsInvalidOtp(false);
    setCountdown(20);
    await destroyRecaptchaVerifier();
    onSignup();
  };

  const startTimer = () => {
    setCountdown(20);
    const timerInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(timerInterval);
          setResendDisabled(false);
        }
        return prevCountdown - 1;
      });
    }, 1000);
    timerIntervalRef.current = timerInterval;
  };

  useEffect(() => {
    const asyncFunction = async () => {
      if (!urlFrom) {
        // console.error('urlFrom is undefined or empty');
        return;
      }

      try {
        const decryptedResult = await decryptionAES(urlFrom);

        if (decryptedResult.success) {
          const url = new URL(decryptedResult.data);
          const path = url.pathname;
          const normalizedPath = path.endsWith("/") ? path.slice(0, -1) : path;

          setIsFromDashboard(normalizedPath.includes("/dashboard"));

          const isURLValid = isValidURL(decryptedResult.data);
          setIsURLValid(isURLValid);

          if (isURLValid.success) {
            // console.log('Valid URL:', isURLValid.data);
          } else {
            console.error("Invalid URL:", isURLValid.error);
          }
        } else {
          console.error("Decryption failed:", decryptedResult.error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    asyncFunction();
  }, [urlFrom]);

  useEffect(() => {
    // console.log("user: ", user);
    // createLog();
  }, [user]);

  const handlePhoneNumberChange = (value) => {
    const inputValue = value;
    const formattedPhoneNumber = inputValue;
    // const formattedPhoneNumber = inputValue.startsWith('1') ? inputValue : `1${inputValue}`;
    // const formattedPhoneNumberWithPlus = `+` + formattedPhoneNumber;
    // console.log("formattedPhoneNumber :", formattedPhoneNumber);
    // console.log("formattedPhoneNumberWithPlus :", formattedPhoneNumberWithPlus);
    setPh(formattedPhoneNumber);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace" && ph === "1") {
      // event.preventDefault();
    }
  };

  return (
    <>
      {isUserRegistered && !urlFrom ? (
        <RedirectToAppStoreAndPlayStore />
      ) : (
        <section className="customer-portal">
          {/* <div className=""> */}
          <div className="mainContentsection flex justify-center items-start sm:flex-row flex-col sm:h-screen h-full max-lg:flex-col">
            <div
              className={`mainContentleft !bg-primary sm:px-0 sm:py-[40px] p-[45px] text-center sm:w-1/2 w-full sm:h-full h-[390px] flex justify-around items-center flex-col`}
              style={{ padding: "0px" }}
            >
              {/* <div> */}
              {/* <img
                  className="sm:max-w-[181px] max-w-[105px] lg:max-h-[52px] max-h-[30px] my-[12px] mx-auto"
                  src={daylightLog}
                  alt=""
                /> */}
              {/* <div className="sm:max-w-[181px] max-w-[105px] lg:max-h-[52px] max-h-[30px] my-[12px] mx-auto">
                                    
                                </div> */}

              {/* </div> */}

              {/* <img
                  className="sm:block hidden max-w[790px] scale-90 absolute  mt-[180px] left-[-85px] bottom-[83px]"
                  src={homeImage}
                  alt=""
                /> */}
              <div style={{ width: "100%", height: "100%" }}>
                {/* <img
                  className="sm:block hidden max-w[790px] ml-[-135px] w-full "
                  src={homeImage}
                  alt=""
                /> */}

                {/* <img className="sm:block hidden max-w[790px] w-[80%]" src={homeImage1} alt="" /> */}
                {/* <img
                  className="sm:hidden block max-w[390px] mt-[30px] mx-auto"
                  src={homeImageMobile}
                  alt=""
                /> */}
                <img
                  className="max-w[790px] w-[100%]  mx-auto sm:h-[100vh] h-[390px] object-cover"
                  style={{
                    width: "100%",
                    // height: '869px'
                  }}
                  src={homeImage2}
                  alt=""
                />
              </div>
            </div>
            <div className="mainContentRight sm:w-1/2 sm:m-auto mt-[18px]  px-[25px] max:sm:px-[15px]">
              <div className="sm:w-[85%] w-full mb-[130px] mx-auto">
                <div className="flex justify-start items-center">
                  <div className="sm:max-w-[181px] max-w-[initial] lg:max-h-[52px] max-h-[40px] my-[12px] ">
                    <DayLightLogo />
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="flex  items-center mx-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M6.4 19.9824L5 18.5824L10.6 12.9824L5 7.38242L6.4 5.98242L12 11.5824L17.6 5.98242L19 7.38242L13.4 12.9824L19 18.5824L17.6 19.9824L12 14.3824L6.4 19.9824Z"
                          fill="black"
                          fill-opacity="0.32"
                        />
                      </svg>
                    </div>
                    <div className="flex  items-center">
                      <img
                        className="sm:max-w-[150px] max-w-[140px] lg:max-h-[52px] max-h-[100px] object-cover"
                        src={ecoPlusLogo}
                        alt="img"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <h3
                    className="text-[#111] 2xl:text-[48px] mt-[35px] mb-[22px] text-[32px] 2xl:leading-[56px] leading-normal font-forma-djr-banner-regular max:2xl:text-[36px]"
                    // style={{ height: '216px', lineHeight: '65px' }}
                  >
                    Sign up and unlock electric rewards from friends.
                  </h3>
                  <p className="text-[rgba(0,0,0,0.60)] sm:text-[17px] text-[14px] sm:leading-[24px] leading-[20px] tracking-[-0.272px] mb-[40px] max-w-[505px] font-forma-djr-deck-regular font-[400px]">
                    <span className="text-primary font-forma-djr-deck-medium">
                      The future of energy is decentralized, sustainable, and
                      reliable.{" "}
                    </span>
                    <br />
                    Unlock rewards by signing up with the Daylight app, and
                    connecting an energy device or completing a quest.
                  </p>

                  <div
                    className=" mb-[18px] w-[354px] max-sm:w-[300px]"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #D9D9D9",
                      gap: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src={emailLogo}
                      style={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "14px",
                      }}
                      alt={""}
                    />

                    <input
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email address"
                      // className="w-full h-[50px] pr-4 rounded-md text-black placeholder:text-black placeholder:opacity-40 hover:!opacity-75 focus-visible:outline-none"
                      className="w-full h-[50px] pr-4 rounded-md text-black font-forma-djr-text-regular placeholder:text-forma-djr-text-regular placeholder:text-black placeholder:text-[16px] placeholder:opacity-40 focus-visible:outline-none"
                    />
                  </div>
                  <div
                    className="mb-[18px] w-[354px] max-sm:w-[300px]"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #D9D9D9",
                      gap: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src={shoppingBagLogo}
                      style={{
                        height: "24px",
                        width: "24px",
                        marginLeft: "14px",
                      }}
                      alt="Promo Icon"
                    />
                    <input
                      name="promoCode"
                      type="text"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      placeholder="Marketplace Code"
                      maxLength="6"
                      className="w-full h-[50px] pr-4 rounded-md text-black font-forma-djr-text-regular placeholder:text-forma-djr-text-regular placeholder:text-black placeholder:text-[16px] placeholder:opacity-40 focus-visible:outline-none"
                    />
                  </div>

                  <div
                    className="mb-[18px] w-[354px] max-sm:w-[300px]"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #D9D9D9",
                      gap: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    {/* <img
                      src={telephoneLogo}
                      style={{ height: '18px', width: '18px', marginLeft: '14px' }}
                    /> */}

                    <PhoneInput
                      specialLabel={""}
                      // onlyCountries={['us', 'ca', 'in', 'do', 'jp', 'kr', 'id', 'th', 'gb']}
                      onlyCountries={["us"]}
                      country={"us"}
                      preserveOrder={["onlyCountries", "preferredCountries"]}
                      value={ph}
                      onChange={handlePhoneNumberChange}
                      onKeyDown={handleKeyDown}
                      placeholder="Phone Number"
                      disableCountryCode={true}
                      disableInitialCountryGuess={false}
                      disableCountryGuess={false}
                      // showDropdown={true}
                      disableDropdown={true}
                      countryCodeEditable={true}
                      // disableDropdown
                      autoFormat={true}
                      className="peer [&>.flag-dropdown]:!bg-transparent [&>.flag-dropdown]:!p-[2px] [&>input]:!bg-transparent [&>input]:py-0 h-full w-full 
                      sm:[&>input]:!w-full [&>input]:!w-[80%] [&>input]:!text-[16px] [&>input]:lg:text-[16px] [&>input]:!h-[50px] [&>input]:!text-black text-[22px] 
                      leading-[20px] font-normal outline outline-0 transition-all  [&>input]:font-forma-djr-text-regular  [&>input]:focus:!ring-0 [&>input]:!outline-none 
                      [&>input]:placeholder-shown:!text-quaternary focus:outline-0 disabled:bg-blue-gray-50 [&>input]:placeholder:!text-[16px] 
                      [&>input]:placeholder:!text-black text-forma-djr-text-regular  [&>input]:placeholder:text-forma-djr-text-regular [&>input]:placeholder:!opacity-40 [&>input]:[appearance:textField] 
                      [&>input]:[&::-webkit-outer-spin-button]:appearance-none [&>input]:[&::-webkit-inner-spin-button]:appearance-none [&>input]:!rounded-none 
                      [&>div]:!border-none [&>div]:!rounded-none [&>div]:!bg-quaternary"
                      // className="peer [&>.flag-dropdown]:!bg-transparent [&>.flag-dropdown]:!p-[2px] [&>input]:!bg-transparent [&>input]:py-0 h-full w-full sm:[&>input]:!w-full [&>input]:!w-[80%] [&>input]:!text-lg [&>input]:lg:text-xl [&>input]:!h-[50px] [&>input]:!text-black text-[22px] leading-[28px] font-normal outline outline-0 transition-all [&>input]:focus:!ring-0 [&>input]:!outline-none [&>input]:placeholder-shown:!text-quaternary focus:outline-0 disabled:bg-blue-gray-50 [&>input]:placeholder:!text-lg [&>input]:lg:placeholder:!text-xl [&>input]:[appearance:textField] [&>input]:[&::-webkit-outer-spin-button]:appearance-none [&>input]:[&::-webkit-inner-spin-button]:appearance-none [&>input]:!rounded-none [&>div]:!border-none [&>div]:!rounded-none [&>div]:!bg-quaternary"
                      // className="peer my-0 [&>.flag-dropdown]:!hidden [&>input]:!pl-0 [&>input]:!bg-transparent [&>input]:py-0 h-full w-full sm:[&>input]:!w-full [&>input]:!w-[80%] [&>input]:!text-lg [&>input]:lg:text-xl [&>input]:!h-[50px] [&>input]:!text-black text-[22px] leading-[28px] font-normal outline outline-0 transition-all [&>input]:focus:!ring-0 [&>input]:!outline-none [&>input]:placeholder-shown:!text-quaternary focus:outline-0 disabled:bg-blue-gray-50 [&>input]:placeholder:!text-lg [&>input]:lg:placeholder:!text-xl [&>input]:[appearance:textField] [&>input]:[&::-webkit-outer-spin-button]:appearance-none [&>input]:[&::-webkit-inner-spin-button]:appearance-none [&>input]:!rounded-none [&>div]:!border-none [&>div]:!rounded-none [&>div]:!bg-quaternary"
                      inputStyle={{
                        border: "none",
                        outline: "none",
                        boxShadow: "none",
                      }}
                      // className="peer my-0 [&>.flag-dropdown]:!hidden [&>input]:!pl-0 [&>input]:!bg-transparent [&>input]:py-0 h-full w-full border-b-2 sm:[&>input]:!w-full [&>input]:!w-[80%] [&>input]:!text-lg [&>input]:lg:text-xl [&>input]:!h-[50px] [&>input]:!text-tertiary  border-tertiary bg-transparent text-[22px] leading-[28px] font-normal text-tertiary outline outline-0 transition-all [&>input]:focus:!ring-0 [&>input]:!outline-none [&>input]:placeholder-shown:!border-quaternary [&>input]:placeholder:!text-quaternary focus:border-tertiary focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 [&>input]:placeholder:!text-lg [&>input]:lg:placeholder:!text-xl [&>input]:[appearance:textField] [&>input]:[&::-webkit-outer-spin-button]:appearance-none [&>input]:[&::-webkit-inner-spin-button]:appearance-none [&>input]:!border-none [&>input]:!rounded-none [&>div]:!border-none [&>div]:!rounded-none [&>div]:!bg-quaternary"
                      //   className="phone-number my-0 [&>input]:!bg-quaternary [&>input]:!w-full [&>input]:!text-lg [&>input]:lg:text-xl [&>input]:!h-[50px] [&>input]:!text-primary [&>input]:focus:!ring-0 [&>input]:!outline-none [&>input]:placeholder:!text-primary [&>input]:placeholder:text-lg [&>input]:lg:placeholder:text-xl [&>input]:[appearance:textField] [&>input]:[&::-webkit-outer-spin-button]:appearance-none [&>input]:[&::-webkit-inner-spin-button]:appearance-none [&>input]:!border-none [&>input]:!rounded-none [&>div]:!border-none [&>div]:!rounded-none [&>div]:!bg-quaternary"
                    />
                    {!isOtpSent && !sendOTPCounter && (
                      <div className="sx:mr-4 text-center w-[150px] ">
                        <span className="sm:text-[14px] text-[12px] leading-[15px] tracking-[0.1px] text-black">
                          <button
                            onClick={onRecaptchaRender}
                            disabled={
                              !(isPhoneNumberValid && isValidEmail) ||
                              verificationLoading
                            }
                            style={{ fontWeight: "700" }}
                            className={`${
                              !(isPhoneNumberValid && isValidEmail) ||
                              verificationLoading
                                ? "text-black opacity-40"
                                : "text-primary"
                            } font-forma-djr-UI-regular text-left disabled:cursor-not-allowed`}
                          >
                            {loading ? (
                              <CgSpinner
                                size={20}
                                className="mr-3 mt-2 animate-spin items-center"
                              />
                            ) : (
                              "Send Code"
                            )}
                          </button>
                        </span>
                      </div>
                    )}

                    {sendOTPCounter ? (
                      <div className="sx:mr-4 text-center w-[180px]  ">
                        <span className="ml-2 sm:text-[14px] text-[12px] leading-[15px] tracking-[0.1px] text-black">
                          {
                            sendOTPCounter ? (
                              <button
                                onClick={handleResendOTP}
                                disabled={
                                  !(isPhoneNumberValid && isValidEmail) ||
                                  verificationLoading
                                }
                                style={{ fontWeight: "700" }}
                                className="text-primary font-forma-djr-UI-regular text-left disabled:opacity-75 disabled:cursor-not-allowed"
                              >
                                Resend Code
                              </button>
                            ) : null // This renders nothing if sendOTPCounter is false
                          }
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    style={{ display: "inline-grid" }}
                    className="w-[354px] max-sm:w-[300px] "
                  >
                    <div className="sm:block w-[354px] max-sm:w-[300px] ">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        OTPLength={6}
                        otpType="number"
                        disabled={!isPhoneNumberValid || !isOtpSent}
                        autoFocus={true}
                        className="opt-container [&>input]:!bg-slate-200 [&>input]:!text-black [&>input]:!text-[25px] "
                      ></OtpInput>
                    </div>
                    {/* <div className="sm:hidden block">
                                                <OtpInput
                                                    value={otp}
                                                    onChange={(e) => {
                                                        setOtp(e);
                                                        setIsInvalidOtp(false);
                                                    }}
                                                    OTPLength={6}
                                                    otpType="number"
                                                    disabled={isOtpSent}
                                                    autoFocus
                                                    className={`opt-container ${otp.length
                                                        ? isInvalidOtp
                                                            ? `[&>input]:!text-[#FF6161] [&>input]:!bg-[#ffd1d1]`
                                                            : `[&>input]:!bg-secondary [&>input]:!text-tertiary`
                                                        : `[&>input]:!bg-tertiary [&>input]:!text-tertiary`
                                                        }  [&>input]:!min-h-[52px] [&>input]:!rounded-[5px]`}></OtpInput>
                                            </div> */}
                    {isInvalidOtp && (
                      <p className="mt-[10px]  sm:text-[16px] font-forma-djr-UI-regular text-[11px] leading-[16px] tracking-[0.1px] text-[#FF6161]">
                        Wrong code, please try again.
                      </p>
                    )}

                    {/* {!isOtpSent && (
                      <Button
                        onClick={onRecaptchaRender}
                        disabled={!isPhoneNumberValid || verificationLoading}
                        size="large"
                        className="!mt-[40px]  !text-orange-600 !py-[12px] sm:!min-w-[350px] min-w-full h-[50px] font-forma-djr-UI-regular !bg-primary hover:!opacity-75 hover:scale-105 disabled:!bg-primary disabled:cursor-none !rounded-[8px] disabled>span:!text-secondary_white"
                        theme="primary">
                        <span
                          className={`flex items-center !text-[18px] !leading-[16px] ${
                            !isPhoneNumberValid || verificationLoading
                              ? '!text-secondary_white'
                              : '!text-secondary_white'
                          }`}>
                          {loading && (
                            <CgSpinner size={20} className="mr-3 animate-spin items-center" />
                          )}{' '}
                          Send Code
                        </span>
                      </Button>
                    )} */}
                    <div className="flex m-2">
                      <input
                        checked={isTermsAndConditionsChecked}
                        id="terms-checkbox"
                        type="checkbox"
                        value="terms"
                        onChange={(e) => {
                          console.log("e :", e.currentTarget.checked);
                          setIsTermsAndConditionsChecked(
                            e.currentTarget.checked
                          );
                        }}
                        className="sm:!w-[30px] !w-[30px] sm:!h-[30px] !h-[30px] sm:scale-100 scale-100 !text-tertiary bg-gray-100 !border-tertiary rounded !focus:ring-red-500 dark:!focus:ring-red-600 dark:ring-offset-tertiary focus:ring-2 dark:bg-tertiary dark:border-tertiary"
                      />

                      <label
                        htmlFor="terms-checkbox"
                        className="ml-2 sm:text-[12px] text-[11px] leading-[16px] tracking-[0.1px] text-black checkbox-container text-left mt-2"
                      >
                        By signing up, you agree to receive communication from
                        Daylight Energy for reminders, promotions, alerts, or
                        company updates. Message frequency varies. Messages and
                        data rates may apply. Reply HELP or call 973-513-7477 to
                        be contacted by our support team. Reply STOP to cancel.
                        View our{" "}
                        <a
                          href={WEBSITE_URL + "legal/terms/"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cursor-pointer"
                        >
                          <u className="font-aeonik-bold">
                            {" "}
                            Terms & Conditions
                          </u>
                        </a>{" "}
                        and{" "}
                        <a
                          href={WEBSITE_URL + "legal/privacy/"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cursor-pointer"
                        >
                          <u className="font-aeonik-bold"> Privacy Policy</u>
                        </a>
                      </label>
                    </div>
                    <Button
                      onClick={onOTPVerify}
                      disabled={
                        !(
                          isPhoneNumberValid &&
                          isValidEmail &&
                          isTermsAndConditionsChecked
                        ) || !(otp && otp.length === 6 && !verificationLoading)
                      }
                      // disabled={!(isPhoneNumberValid && isValidEmail)|| verificationLoading }
                      size="large"
                      className="!mt-[16px] !py-[12px] sm:!min-w-[207px] min-w-full h-[50px] font-forma-djr-UI-regular !bg-primary  
                      hover:!opacity-75 hover:scale-105 disabled:!bg-[#00000014] disabled:cursor-none !rounded-[8px]"
                      theme="primary"
                    >
                      <span
                        className={`flex items-center !text-[15px]  !leading-[16px] font-forma-djr-UI-regular disabled:!text-[#00000014]${
                          !(otp && otp.length === 6 && !verificationLoading)
                            ? "!tertiary"
                            : "!text-secondary_white"
                        }`}
                      >
                        {verificationLoading && (
                          <CgSpinner
                            size={20}
                            className="mr-3 animate-spin items-center"
                          />
                        )}{" "}
                        Submit
                      </span>
                    </Button>

                    {sendOTPCounter ? (
                      <div className="sm:mr-4 sm:mt-[40px] mt-[18px] text-center">
                        <span className="ml-2 sm:text-[15px] text-[12px] leading-[15px] tracking-[0.1px] text-black">
                          {
                            resendDisabled ? (
                              <span className="text-primary font-forma-djr-UI-regular">
                                Resend Code in {countdown} seconds
                              </span>
                            ) : null // This renders nothing if sendOTPCounter is false
                          }
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div className="flex items-center mr-4 mb-[40px]">
                                            <input
                                                checked={isTermsAndConditionsChecked}
                                                id="terms-checkbox"
                                                type="checkbox"
                                                value="terms"
                                                onChange={(e) => {
                                                    console.log('e :', e.currentTarget.checked);
                                                    setIsTermsAndConditionsChecked(e.currentTarget.checked);
                                                }}
                                                className="sm:!w-[30px] !w-[25px] sm:!h-[30px] !h-[25px] sm:scale-100 scale-150 !text-tertiary bg-gray-100 !border-tertiary rounded !focus:ring-red-500 dark:!focus:ring-red-600 dark:ring-offset-tertiary focus:ring-2 dark:bg-tertiary dark:border-tertiary"
                                            />

                                            <label
                                                htmlFor="terms-checkbox"
                                                className="ml-2 sm:text-[12px] text-[11px] leading-[16px] tracking-[0.1px] text-black checkbox-container text-left">
                                                By signing up, you agree to receive communication from Daylight Energy for
                                                reminders, promotions, alerts, or company updates. Message frequency varies.
                                                Messages and data rates may apply. Reply HELP or call 973-513-7477 to be
                                                contacted by our support team. Reply STOP to cancel. View our{' '}
                                                <a
                                                    href={WEBSITE_URL + 'legal/terms/'}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="cursor-pointer">
                                                    <u className="font-aeonik-bold"> Terms & Conditions</u>
                                                </a>{' '}
                                                and{' '}
                                                <a
                                                    href={WEBSITE_URL + 'legal/privacy/'}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="cursor-pointer">
                                                    <u className="font-aeonik-bold"> Privacy Policy</u>
                                                </a>
                                            </label>
                                        </div> */}

                  {/* <Button
                                            onClick={onRecaptchaRender}
                                            disabled={!(ph.length === 11 && isTermsAndConditionsChecked)}
                                            size="large"
                                            className="!py-[12px] sm:!min-w-[207px] min-w-full h-[50px] font-aeonik-bold !rounded-full !bg-tertiary hover:!opacity-75 hover:scale-105 disabled:!bg-gray-200 disabled:cursor-none"
                                            theme="primary">
                                            <span className="flex items-center !text-[18px] !text-primary !leading-[16px]">
                                                {loading && (
                                                    <CgSpinner size={20} className="animate-spin mr-3 items-center" />
                                                )}{' '}
                                                Submit
                                            </span>
                                        </Button> */}
                </div>

                <div id="recaptcha-container" ref={recaptchaContainerRef}></div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
      )}
    </>
  );
};

export default MainScreenV1;
